import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Happy Valentines Day!`}</em></p>
    <p>{`Push Press 4-4-4\\@85% 1RM`}</p>
    <p>{`Bent Side Laterals 8-8-8`}</p>
    <p>{`then,`}</p>
    <p>{`10:00 EMOM of:`}</p>
    <p>{`1-Push Press (using 85% 1RM)`}</p>
    <p>{`1-Push Jerk`}</p>
    <p>{`1-Split Jerk`}</p>
    <p>{`then at minute 11:00:`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`20-KBS’s (53/35)`}</p>
    <p>{`20-Ring Dips`}</p>
    <p><strong parentName="p">{`*`}{`The CrossFit Open starts February 23rd! Sign up now at:
Games.crossfit.com.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      